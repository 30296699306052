import React from "react";
import { Link } from "react-router-dom";
// import solutionsBg from "../../assets/img/solutions-bg.jpg";
const Solutions = () => {
  return (
    <section id="solution" className="pricing section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Our Solutions</h2>
        <p>
          We have a propriety Data Engine behind our Transformative AI products.
          This gives us unparalleled agility, scalability, and performance.
        </p>
      </div>
      {/* <div className="container mb-4" data-aos="zoom-in" data-aos-delay="100">
        <div className="row text-center d-flex justify-content-center">
          <div className="col-lg-6">
              <img src={solutionsBg} className="img-fluid" />
          </div>
        </div>
      </div> */}
      <div className="container" data-aos="fade-up">
        <h3>Our current product offering include</h3>
      </div>
      <div className="container">
        <div className="row g-4">
          <div
            className="col-lg-6"
            data-aos="zoom-in-right"
            data-aos-delay="100"
          >
            <div className="pricing-item">
              <h3>FleetGo - for Transport & Logistics</h3>
              <div className="icon">
                <i className="bi bi-truck"></i>
              </div>

              <div className="text-center">
                <Link to="/solutions-fleet" className="buy-btn">
                  Read more
                </Link>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6"
            data-aos="zoom-in-left"
            data-aos-delay="100"
          >
            <div className="pricing-item">
              <h3>Vedum - for Taxation</h3>
              <div className="icon">
                <i className="bi bi-calculator"></i>
              </div>

              <div className="text-center">
                <Link to="/solutions-tax" className="buy-btn">
                  Read more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Solutions;
