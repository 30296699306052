import React, { useEffect } from "react";
import Hero from "./Hero";
import { useLocation } from "react-router-dom";
import taxBg from "../../assets/img/law-ai-banner.jpg";

const SolutionsVedum = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/solutions-tax") {
      const element = document.getElementById("solutions-tax");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.pathname]);
  return (
    <main className="main">
      <Hero />
      <section id="solutions-tax" className="services section">
        <div className="container section-title" data-aos="fade-up">
          <h2>Vedum</h2>
          <p>
            <strong>
              Vedum an AI driven solution is transforming the way tax lawyers
              and consultants currently work.
            </strong>
          </p>
          <p>
            This innovative tool enables users to quickly discover relevant case
            laws, notifications, and related information, drastically reducing
            the time spent on research. Each response comes with verified
            references, providing reliable decision intelligence and supporting
            consultants in making informed decisions efficiently.
          </p>
          <p>
            <strong>Vedum</strong>, harnesses
            25 years of pioneering expertise in Indian Taxation Knowledge
            Management. With a legacy of excellence and a deep understanding of
            the intricacies of Indian tax laws, TIOL has been at the forefront
            of providing reliable, comprehensive, and up-to-date tax information
          </p>
        </div>
        <div
          className="container mb-5 text-center"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <img
            src={taxBg}
            alt=""
            className="img-fluid"
            style={{ maxHeight: "500px" }}
          />
        </div>
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="100">
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="bi bi-search"></i>
                </div>
                <div>
                  <h4 className="title">
                    <p className="stretched-link">Context Based Search</p>
                  </h4>
                  <p className="description">
                    Leverage the power of AI to discover relevant case laws
                    notification
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="200">
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="bi bi-pencil-square"></i>
                </div>
                <div>
                  <h4 className="title">
                    <p className="stretched-link">Drafting</p>
                  </h4>
                  <p className="description">
                    Draft notices, petitions, and other legal documents more
                    quickly while minimizing human error, ensuring no detail is
                    overlooked. Our AI-powered solution streamlines the drafting
                    process, enhancing accuracy and efficiency.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="300">
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="bi bi-filetype-ai"></i>
                </div>
                <div>
                  <h4 className="title">
                    <p className="stretched-link">Auto Response to GST SSN</p>
                  </h4>
                  <p className="description">
                    Generate comprehensive responses to GST notices effortlessly
                    with Vedum. The solution scans and analyzes relevant case
                    laws, notifications, and government guidelines to provide
                    accurate and well-informed responses.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="400">
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="bi bi-graph-up"></i>
                </div>
                <div>
                  <h4 className="title">
                    <p className="stretched-link">Trending Tax Issues</p>
                  </h4>
                  <p className="description">
                    Stay informed on prevalent tax issues and understand the
                    court's perspective on each matter. Our comprehensive
                    resources provide insights into current tax challenges and
                    detailed analyses of court rulings, helping you navigate the
                    complexities of tax law with confidence.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="500">
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="bi bi-shield-check"></i>
                </div>
                <div>
                  <h4 className="title">
                    <p className="stretched-link">Secure Environment</p>
                  </h4>
                  <p className="description">
                    Experience unparalleled security with industry-grade
                    implementations, ensuring your work environment is the most
                    secure. Our robust security measures protect your data and
                    maintain confidentiality, giving you peace of mind while
                    focusing on what matters most.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="600">
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="bi bi-phone-vibrate"></i>
                </div>
                <div>
                  <h4 className="title">
                    <p className="stretched-link">Tailored Pricing</p>
                  </h4>
                  <p className="description">
                    Contact us for pricing that suit your needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default SolutionsVedum;
